@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

*{
    box-sizing: border-box;
}

body{
    background-color: #111;
    color: #fff;
    margin: 0;
    font-family: 'Roboto', sans-serif;
}

.landing-page{
    background-size: cover;
    background-position: center;
    /* background-image: url("../public/images/home-bg1.jpg"); */
    height: 100vh;
}

.landing-page-vertical{
    width: inherit;
    height: inherit;
    background: linear-gradient(to top, #111 10%, transparent 90%);
}

.landing-page-horizontal{
    width: inherit;
    height: inherit;
    /* background: linear-gradient(to right, #111 30%, transparent 70%); */
}

.landing-page-title{
    font-size: 60px;
    font-weight: bold;
    padding-top: 250px;
    padding-left: 150px;
    /* margin-top: 70px; */
}

.landing-page-description{
    font-size: 20px;
    font-weight: bold;
    padding-left: 150px;
    padding-bottom: 10px;
    /* margin-top: 35px; */
}

.landing-page-links{
    padding-left: 150px;
    display: flex;
    width: inherit;
    justify-content: start;
}

.landing-page-links a{
    margin-right: 20px;
    margin-top: 10px;
}

.about-panel{
    color: #000;
}

.img-stock{
    float: right;
}

.img-platform, .img-client{
    float: left;
}

.txt-stock{
    text-align: justify;
    padding-top: 40px;
}

.txt-platform{
    text-align: justify;
    padding-top: 60px;
}

footer{
    background-color: #111;
    padding-top: 20px;
    padding-bottom: 5px;
}