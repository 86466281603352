:root {
--bs-body-font-family: 'Roboto', sans-serif;
--bs-body-color: #fff;

// --bs-primary:  #fff;
}

// $primary: #fff;
.btn-outline-primary {
    --bs-btn-color: #fff;
    --bs-btn-border-color: #fff;
    --bs-btn-hover-color: #0d6efd;//0d6efd
    --bs-btn-hover-bg: #fff;
    --bs-btn-hover-border-color: #fff;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #0d6efd; //0d6efd
    --bs-btn-active-bg: #fff;
    --bs-btn-active-border-color: #fff;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #fff;
    --bs-gradient: none;
    }